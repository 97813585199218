import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { AnimatePresence, motion } from "framer-motion"
import theme from "styles/theme"
import ShadowWrapper from "components/ShadowWrapper"
import OpenIndicator from "components/OpenIndicator"
import InvisibleButton from "components/InvisibleButton"

const ExpansionCard = ({ title, copy, cards, slug }) => (
  <div
    id={slug}
    css={{
      maxWidth: 1228 + 50,
      margin: "50px auto",
      padding: "0 25px",
      "& + &": {
        marginTop: 100,
        [theme.mq.mobile]: {
          marginTop: 50,
        },
      },
    }}
  >
    {title && (
      <h2
        css={{
          ...theme.h2,
          paddingLeft: "0.45em",
          borderLeft: `4px solid ${theme.colors.accentColor}`,
          position: "relative",
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 8,
            height: 1,
            left: 0,
            background: theme.colors.accentColor,
          },
          ":before": { top: 0 },
          ":after": { bottom: 0 },
          [theme.mq.mobile]: {
            borderLeftWidth: 2,
            ":before, :after": {
              width: 4,
            },
          },
        }}
      >
        {title}
      </h2>
    )}

    {copy && (
      <p
        css={{
          ...theme.p2,
          maxWidth: title ? "36em" : "35em",
          paddingLeft: title ? "1.09em" : 0,
          color: theme.colors.secondaryText,
          margin: "0.675em 0 0 0",
          [theme.mq.mobile]: {
            paddingLeft: 0,
          },
        }}
      >
        {copy}
      </p>
    )}

    <div
      css={{
        marginTop: 50,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "30px 20px",
        [theme.mq.smallMobile]: {
          gridTemplateColumns: "1fr",
          gap: 20,
          maxWidth: 350,
          margin: "30px auto",
        },
      }}
    >
      {cards.map(({ _type, _key, ...cardProps }) => {
        const Card = _type === "listExpansionCard" ? ListCard : TextCard
        return <Card type={_type} key={_key} {...cardProps} />
      })}
    </div>
  </div>
)

export default ExpansionCard

ExpansionCard.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.oneOf(["listExpansionCard", "textExpansionCard"])
        .isRequired,
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

const Card = ({ title, image, children, className }) => {
  const [active, setActive] = useState(false)

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InvisibleButton
        css={theme.expandFull({
          flex: "1 1 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          cursor: "pointer",
          background: theme.colors.floatingContainerBackground,
          boxShadow: theme.shadows.tight,
          borderRadius: [4, 3, 2],
          padding: [24, 18, 12],
          userSelect: "none",
        })}
        onClick={() => setActive((prev) => !prev)}
      >
        <h3 css={theme.h3}>{title}</h3>
        <OpenIndicator open={active} />
      </InvisibleButton>

      <div
        css={{
          color: theme.colors.secondaryText,
          background: theme.colors.floatingContainerBackground,
          boxShadow: theme.shadows.tight,
          borderRadius: 4,
          overflow: "hidden",
          position: "relative",
          ":after": {
            content: "''",
            display: "block",
            paddingBottom: "75%",
          },
        }}
      >
        <AnimatePresence initial={false}>
          {active ? (
            <motion.div
              key="content"
              initial={{ y: "50%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "50%", opacity: 0 }}
              transition={spring}
              css={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                overflowY: "auto",
              }}
              className={className}
            >
              {children}
            </motion.div>
          ) : (
            <motion.div
              key="media"
              initial={{ y: "-50%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "-50%", opacity: 0 }}
              transition={spring}
              css={{
                position: "absolute",
                width: "100%",
                height: "100%",
                padding: 10,
                [theme.mq.smallMobile]: {
                  padding: 8,
                },
              }}
            >
              <ShadowWrapper
                css={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <SanityImage
                  width={584}
                  height={434}
                  alt=""
                  {...image}
                  css={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                  sizes="(max-width: 400px) calc(100vw - 66px), (max-width: 648px) 334px, (max-width: 1280px) calc((100vw - 110px) / 2), 584px"
                />
              </ShadowWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const ListCard = ({ contentTitle, listContent, ...rest }) => (
  <Card
    {...rest}
    css={{
      padding: "16px 20px",
      [theme.mq.mobile]: {
        padding: 15,
      },
    }}
  >
    <h4
      css={{
        fontSize: "inherit",
        fontWeight: 500,
        padding: "11px 20px 6px",
        borderBottom: `1px solid ${theme.colors.divider}`,
        [theme.mq.mobile]: {
          border: 0,
          padding: 0,
          marginBottom: "1em",
        },
      }}
    >
      {contentTitle}
    </h4>

    <ul css={{ listStyle: "none", margin: 0, padding: 0 }}>
      {listContent.map((item, index) => (
        <li
          key={index}
          css={{
            padding: "11px 20px 6px",
            borderBottom: `1px solid ${theme.colors.divider}`,
            [theme.mq.mobile]: {
              border: 0,
              padding: 0,
            },
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  </Card>
)

ListCard.propTypes = {
  contentTitle: PropTypes.string.isRequired,
  listContent: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const TextCard = ({ contentTitle, textContent, ...rest }) => (
  <Card {...rest} css={{ padding: 24, [theme.mq.mobile]: { padding: 15 } }}>
    <h4 css={{ fontSize: "inherit", fontWeight: 500 }}>{contentTitle}</h4>
    <p css={{ maxWidth: "23em" }}>{textContent}</p>
  </Card>
)

TextCard.propTypes = {
  contentTitle: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const spring = {
  type: "spring",
  damping: 24,
  stiffness: 200,
}

export const query = graphql`
  fragment ExpansionCardBlock on SanityExpansionCard {
    title
    copy
    cards {
      ... on ExpansionCard {
        _type
        _key
        title
        contentTitle
        image {
          ...ImageWithPreview
        }
      }
      ... on SanityTextExpansionCard {
        textContent
      }
      ... on SanityListExpansionCard {
        listContent
      }
    }
  }
`
